var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', {
    staticClass: "text-h4 secondary primary--text"
  }, [_vm._v(" " + _vm._s(_vm.$t('roles.edit.title')) + ": " + _vm._s(_vm.role.name) + " ")]), _c('v-card-text', {
    staticClass: "pt-4"
  }, [_c('v-form', {
    ref: "editRoleForm",
    attrs: {
      "id": "edit-role-form",
      "lazy-validation": ""
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.editRole.apply(null, arguments);
      }
    }
  }, [_c('role-form', {
    attrs: {
      "translations": _vm.translations
    },
    on: {
      "update:translations": function updateTranslations($event) {
        _vm.translations = $event;
      }
    },
    model: {
      value: _vm.roleToEdit,
      callback: function callback($$v) {
        _vm.roleToEdit = $$v;
      },
      expression: "roleToEdit"
    }
  }), _c('v-divider'), _c('v-row', [_c('v-col', [_c('h4', {
    staticClass: "text-h4"
  }, [_vm._v(_vm._s(_vm.$t('roles.edit.details')))])])], 1), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', [_c('p', [_c('span', {
    staticClass: "text-body-1"
  }, [_vm._v(_vm._s(_vm.$t('roles.edit.usersWithRole')) + ": ")]), _c('span', [_vm._v(_vm._s(_vm.usersWithRoleCount || '0'))])])])], 1)], 1)], 1), _c('v-card-actions', [_c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "elevation": "0"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('form.action.cancel')))]), _c('v-spacer'), _c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "elevation": "0",
      "type": "submit",
      "form": "edit-role-form",
      "color": "primary",
      "loading": _vm.isLoading
    }
  }, [_c('span', {
    staticClass: "ml-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('form.action.editRole')) + " ")])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }