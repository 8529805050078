
import { defineComponent, ref, computed } from '@vue/composition-api'
import { DataTableHeader } from 'vuetify'
import { mdiPlus, mdiPencil, mdiDelete, mdiShieldLock } from '@mdi/js'

import EditRoleDialog from '@/views/admin/roles/EditRoleDialog.vue'
import AddRoleDialog from '@/views/admin/roles/AddRoleDialog.vue'
import DeleteRoleDialog from '@/views/admin/roles/DeleteRoleDialog.vue'

import { tf } from '@/plugins/i18n'

import { useAuthGetters } from '@/store'

import { UserRole } from '@/api/types/role'
import { Rights } from '@/api/types/right'

import { rolesApi } from '@/api'

import usePagination from '@/composables/usePagination'

export default defineComponent({
  name: 'AdminRolesView',
  components: {
    EditRoleDialog,
    AddRoleDialog,
    DeleteRoleDialog,
  },
  setup(_, { root }) {
    const { exec: getRolesCall, data: roles, isLoading, paginationResponse } = rolesApi.useGetRoles()

    const totalRoles = computed(() => paginationResponse.value.totalElements)

    const { vuetifyTableOptions, paginationParams } = usePagination()

    const { hasRight } = useAuthGetters()
    const hasCreateRight = computed(() => hasRight.value(Rights.ROLE_CREATE))
    const hasUpdateRight = computed(() => hasRight.value(Rights.ROLE_UPDATE))
    const hasDeleteRight = computed(() => hasRight.value(Rights.ROLE_DELETE))

    /**
     * Base Headers of the table every user that can visit this site can see
     */
    const defaultHeaders: DataTableHeader[] = [
      {
        text: '',
        value: 'protected',
        sortable: false,
      },
      {
        text: root.$t('form.field.id') as string,
        value: 'id',
      },
      {
        text: root.$t('form.field.name') as string,
        value: 'name',
      },
      {
        text: root.$t('form.field.description') as string,
        value: 'description',
      },
      {
        text: root.$tc('form.field.right', 2) as string,
        value: 'rights',
        sortable: false,
      },
    ]

    /**
     * Table Headers with the Actions tab if the unser can either edit or delete
     */
    const tableHeaders = computed<DataTableHeader[]>(() =>
      defaultHeaders.concat(
        hasUpdateRight.value || hasDeleteRight.value
          ? [
              {
                text: '',
                value: 'actions',
                sortable: false,
              },
            ]
          : []
      )
    )

    const getRoles = async () => {
      getRolesCall({ params: { ...paginationParams.value } })
    }

    // Edit Role
    const isEditActive = ref(false)
    const roleToEdit = ref<UserRole | null>(null)

    const openEditDialog = (role: UserRole) => {
      if (hasUpdateRight.value) {
        roleToEdit.value = role
        isEditActive.value = true
      }
    }

    const onRoleEdited = (editedRole: UserRole) => {
      const index = roles.value.findIndex((role) => role.name === editedRole.name)
      if (index >= 0) {
        root.$set(roles.value, index, editedRole)
      }
      isEditActive.value = false
    }

    // Add Role
    const isCreateActive = ref(false)

    //Delete Role
    const isDeleteActive = ref(false)
    const roleToDelete = ref<UserRole | null>(null)

    const openDeleteDialog = (user: UserRole) => {
      if (hasDeleteRight.value) {
        roleToDelete.value = user
        isDeleteActive.value = true
      }
    }

    return {
      icons: { mdiPlus, mdiPencil, mdiDelete, mdiShieldLock },
      hasCreateRight,
      hasUpdateRight,
      hasDeleteRight,
      tableHeaders,
      vuetifyTableOptions,
      isLoading,
      roles,
      totalRoles,
      getRoles,
      isEditActive,
      roleToEdit,
      openEditDialog,
      onRoleEdited,
      isCreateActive,
      isDeleteActive,
      roleToDelete,
      openDeleteDialog,
      tf,
    }
  },
})
