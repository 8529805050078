
import { defineComponent, ref } from '@vue/composition-api'
import { mdiCheck } from '@mdi/js'

import RoleForm from '@/views/admin/roles/RoleForm.vue'

import { reloadLanguage } from '@/plugins/i18n'

import { useNotify } from '@/store'

import { NewUserRole, UserRole } from '@/api/types/role'
import { Translation } from '@/api/types/translation'

import { rolesApi, translationsApi } from '@/api'

import { ValidationForm } from '@/utils/types/validation'
import { isOfType } from '@/utils/types/isOfType'

export default defineComponent({
  name: 'AddRoleDialog',
  components: {
    RoleForm,
  },
  setup(_, { root, emit }) {
    const { createRole, isLoading } = rolesApi.useCreateRole()

    const { addNotification } = useNotify()

    const addRoleForm = ref<ValidationForm | null>(null)

    const editRole = ref<NewUserRole>({
      name: '',
      description: '',
      rights: [],
    })

    const { updateSingleTranslation } = translationsApi.useUpdateSingleTranslation()
    const translations = ref<Translation[]>([])

    const addRole = () => {
      if (addRoleForm.value?.validate()) {
        const promises: Promise<UserRole | undefined | string>[] = [
          createRole(editRole.value),
          Promise.all(translations.value.filter((t) => t.value).map(updateSingleTranslation)).then(() =>
            reloadLanguage()
          ),
        ]

        Promise.all(promises)
          .then((response) => {
            addNotification({
              type: 'success',
              text: root.$t('roles.create.success', {
                name:
                  typeof response[0] !== 'string' && isOfType<UserRole>(response[0]?.name, 'name')
                    ? response[0]?.name
                    : response[0],
              }) as string,
            })
            emit('role-added', response[0])
            emit('close')
          })
          .catch((error) => {
            error.userMessage = root.$t('roles.create.error', {
              name: editRole.value.name,
            })
            throw error
          })
      }
    }

    return {
      icons: { mdiCheck },
      addRoleForm,
      editRole,
      addRole,
      isLoading,
      translations,
    }
  },
})
