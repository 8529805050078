
import { computed, defineComponent, PropType } from '@vue/composition-api'

import { UserRole } from '@/api/types/role'
import { useDeleteRole } from '@/api/role'
import { useDeleteTranslation } from '@/api/translation'
import { usersApi } from '@/api'
import { useNotify } from '@/store'

export default defineComponent({
  name: 'DeleteRoleDialog',
  props: {
    role: {
      type: Object as PropType<UserRole>,
      required: true,
    },
  },
  setup: (props, { root, emit }) => {
    const { deleteTranslation } = useDeleteTranslation()
    const { deleteRole: deleteRoleRequest, isLoading } = useDeleteRole()
    const { getUsers, paginationResponse } = usersApi.useGetUsers()

    const { addNotification } = useNotify()

    // use total elements to count the amount of users for the given role
    getUsers(
      {
        page: 0,
        size: 1,
      },
      { role: props.role.name }
    )

    const usersWithRoleCount = computed(() => paginationResponse.value.totalElements)

    const deleteRole = () => {
      const promises = [deleteRoleRequest(props.role.name), deleteTranslation(`roles.names.${props.role.name}`)]

      Promise.all(promises)
        .then(() => {
          addNotification({
            type: 'success',
            text: root.$t('roles.delete.success', {
              name: props.role.name,
            }) as string,
          })
          emit('role-deleted')
          emit('close')
        })
        .catch((error) => {
          error.userMessage = root.$t('roles.delete.error', {
            name: props.role.name,
          })
          throw error
        })
    }

    return {
      deleteRole,
      usersWithRoleCount,
      isLoading,
    }
  },
})
