var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', {
    staticClass: "text-h4 secondary primary--text"
  }, [_vm._v(" " + _vm._s(_vm.$t('roles.delete.title')) + " ")]), _c('v-card-text', {
    staticClass: "pt-4"
  }, [_vm.usersWithRoleCount ? _c('v-alert', {
    attrs: {
      "type": "warning"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('roles.delete.promptHasUsers', {
    name: _vm.role.name
  })) + " ")]) : _c('v-alert', {
    attrs: {
      "text": "",
      "type": "error"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('roles.delete.prompt', {
    name: _vm.role.name
  })) + " ")])], 1), _c('v-card-actions', [_c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "text": "",
      "elevation": "0"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('form.action.cancel')))]), _c('v-spacer'), _c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "elevation": "0",
      "color": "error",
      "loading": _vm.isLoading,
      "disabled": !!_vm.usersWithRoleCount
    },
    on: {
      "click": _vm.deleteRole
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('form.action.deleteRole')) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }