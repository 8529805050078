var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', {
    staticClass: "text-h4 secondary primary--text"
  }, [_vm._v(" " + _vm._s(_vm.$t('roles.create.title')) + " ")]), _c('v-card-text', {
    staticClass: "pt-4"
  }, [_c('v-form', {
    ref: "addRoleForm",
    attrs: {
      "id": "add-role-form",
      "lazy-validation": ""
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.addRole.apply(null, arguments);
      }
    }
  }, [_c('role-form', {
    attrs: {
      "translations": _vm.translations
    },
    on: {
      "update:translations": function updateTranslations($event) {
        _vm.translations = $event;
      }
    },
    model: {
      value: _vm.editRole,
      callback: function callback($$v) {
        _vm.editRole = $$v;
      },
      expression: "editRole"
    }
  })], 1)], 1), _c('v-card-actions', [_c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "text": "",
      "elevation": "0"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('form.action.cancel')))]), _c('v-spacer'), _c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "elevation": "0",
      "type": "submit",
      "form": "add-role-form",
      "color": "primary",
      "loading": _vm.isLoading
    }
  }, [_c('span', {
    staticClass: "ml-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('form.action.addRole')) + " ")])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }