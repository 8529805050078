
import { computed, defineComponent, PropType, reactive, ref, toRefs } from '@vue/composition-api'

import CommonAutocomplete from '@/components/common/CommonAutocomplete.vue'

import { useGetRights } from '@/api/right'
import { NewUserRole } from '@/api/types/role'
import { Translation } from '@/api/types/translation'
import { useGetTranslations } from '@/api/translation'

import { isRequired, validRoleName } from '@/utils/validation'

import CommonFlagIcon from '@/components/common/CommonFlagIcon.vue'

export default defineComponent({
  name: 'RoleForm',
  components: {
    CommonFlagIcon,
    CommonAutocomplete,
  },
  props: {
    value: {
      type: Object as PropType<NewUserRole>,
      required: true,
    },
    translations: {
      type: Array as PropType<Translation[]>,
      required: true,
    },
  },
  setup(props, { root, emit }) {
    const { getRights, data: rightsData } = useGetRights()

    const sortedRights = computed(() => {
      const tempRights = [...rightsData.value]
      return tempRights.sort((a, b) => a.authority.localeCompare(b.authority))
    })

    getRights({
      size: 9999,
      page: 0,
    })

    const role = reactive(props.value)

    const updateRole = () => {
      role.rights = role.rights.map((right) => {
        return { authority: right }
      })
      emit('input', role)
    }

    const { getTranslations } = useGetTranslations()
    const implementedLocales: string[] = root.$store.state.locale.implementedLocales

    const initialTranslations: Translation[] = []
    implementedLocales.forEach((locale) =>
      initialTranslations.push({
        locale,
        key: `roles.names.${role.name}`,
        value: '',
      })
    )
    implementedLocales.forEach((locale) =>
      initialTranslations.push({
        locale,
        key: `roles.descriptions.${role.name}`,
        value: '',
      })
    )

    const newTranslations = ref<Translation[]>(initialTranslations)
    const nameTranslations = computed(() => newTranslations.value.filter((t) => t.key.startsWith('roles.names.')))
    const descriptionTranslations = computed(() =>
      newTranslations.value.filter((t) => t.key.startsWith('roles.descriptions.'))
    )

    // load translatios to prefill translation fields if role is edit
    if (role.name) {
      getTranslations()
        .then((translationMap) => {
          if (translationMap[`roles.names.${role.name}`]) {
            newTranslations.value = newTranslations.value.filter((t) => t.key !== `roles.names.${role.name}`)
            newTranslations.value = [...newTranslations.value, ...translationMap[`roles.names.${role.name}`]]
          }
          if (translationMap[`roles.descriptions.${role.name}`]) {
            newTranslations.value = newTranslations.value.filter((t) => t.key !== `roles.descriptions.${role.name}`)
            newTranslations.value = [...newTranslations.value, ...translationMap[`roles.descriptions.${role.name}`]]
          }
        })
        .finally(() => {
          emit('update:translations', newTranslations.value)
        })
    } else {
      emit('update:translations', newTranslations.value)
    }

    const onNameChanged = (name: string) => {
      newTranslations.value
        .filter((t) => t.key.startsWith('roles.names'))
        .forEach((translation) => {
          translation.key = `roles.names.${name}`
        })
      newTranslations.value
        .filter((t) => t.key.startsWith('roles.descriptions'))
        .forEach((translation) => {
          translation.key = `roles.descriptions.${name}`
        })
      emit('update:translations', newTranslations.value)
      updateRole()
    }

    return {
      ...toRefs(role),
      updateRole,
      sortedRights,
      isRequired,
      validRoleName,
      onNameChanged,
      newTranslations,
      nameTranslations,
      descriptionTranslations,
    }
  },
})
