
import { defineComponent, PropType, computed, ref } from '@vue/composition-api'
import { mdiCheck } from '@mdi/js'

import { reloadLanguage } from '@/plugins/i18n'

import { useNotify } from '@/store'

import { UserRole, UserRoleUpdate } from '@/api/types/role'
import { Translation } from '@/api/types/translation'

import { usersApi, rolesApi, translationsApi } from '@/api'

import { ValidationForm } from '@/utils/types/validation'

import RoleForm from './RoleForm.vue'
import { isOfType } from '@/utils/types/isOfType'

export default defineComponent({
  name: 'EditRoleDialog',
  components: {
    RoleForm,
  },
  props: {
    role: {
      type: Object as PropType<UserRoleUpdate>,
      required: true,
    },
  },
  setup: (props, { root, emit }) => {
    const { updateRole, isLoading } = rolesApi.useUpdateRole()
    const { getUsers, paginationResponse } = usersApi.useGetUsers()

    const { addNotification } = useNotify()

    const editRoleForm = ref<ValidationForm | null>(null)

    const roleToEdit = ref<UserRoleUpdate>({ ...props.role })

    // use total elements to count the amount of users for the given role
    getUsers(
      {
        page: 0,
        size: 1,
      },
      { role: props.role.name }
    )

    const usersWithRoleCount = computed(() => paginationResponse.value.totalElements)

    const { deleteTranslation } = translationsApi.useDeleteTranslation()
    const { updateSingleTranslation } = translationsApi.useUpdateSingleTranslation()
    const translations = ref<Translation[]>([])

    const editRole = () => {
      if (editRoleForm.value?.validate()) {
        const promises: Promise<UserRole | string | undefined | void>[] = [
          updateRole(roleToEdit.value),
          Promise.all(translations.value.filter((t) => t.value).map(updateSingleTranslation)).then(() =>
            reloadLanguage()
          ),
        ]

        if (roleToEdit.value.name !== props.role.name)
          promises.push(deleteTranslation(`roles.names.${props.role.name}`))

        Promise.all(promises)
          .then((response) => {
            addNotification({
              type: 'success',
              text: root.$t('roles.update.success', {
                name:
                  typeof response[0] !== 'string' && isOfType<UserRole>(response[0]?.name, 'name')
                    ? response[0]?.name
                    : response[0],
              }) as string,
            })
            emit('role-edited', response[0])
            emit('close')
          })
          .catch((error) => {
            error.userMessage = root.$t('roles.update.error', {
              name: roleToEdit.value.name,
            })
            throw error
          })
      }
    }

    return {
      icons: { mdiCheck },
      editRoleForm,
      roleToEdit,
      usersWithRoleCount,
      editRole,
      isLoading,
      translations,
    }
  },
})
