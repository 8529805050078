var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('form.placeholder.role.name') + '*',
      "rules": [_vm.isRequired(_vm.name, _vm.$t('form.field.name')), _vm.validRoleName(_vm.name)],
      "outlined": ""
    },
    on: {
      "input": _vm.onNameChanged
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1)], 1), _vm._l(_vm.nameTranslations, function (translation) {
    return _c('v-row', {
      key: 'name-' + translation.locale,
      attrs: {
        "dense": ""
      }
    }, [_c('v-col', [_c('v-text-field', {
      attrs: {
        "label": _vm.$t('form.field.name') + ' (' + _vm.$t("locale.".concat(translation.locale)) + ') *',
        "rules": [_vm.isRequired(translation.value, _vm.$t("locale.".concat(translation.locale)))],
        "aria-required": "",
        "outlined": ""
      },
      on: {
        "input": function input($event) {
          return _vm.$emit('update:translations', _vm.newTranslations);
        }
      },
      scopedSlots: _vm._u([{
        key: "prepend-inner",
        fn: function fn() {
          return [_c('CommonFlagIcon', {
            attrs: {
              "locale": translation.locale
            }
          })];
        },
        proxy: true
      }], null, true),
      model: {
        value: translation.value,
        callback: function callback($$v) {
          _vm.$set(translation, "value", $$v);
        },
        expression: "translation.value"
      }
    })], 1)], 1);
  }), _vm._l(_vm.descriptionTranslations, function (translation) {
    return _c('v-row', {
      key: 'desc-' + translation.locale,
      attrs: {
        "dense": ""
      }
    }, [_c('v-col', [_c('v-text-field', {
      attrs: {
        "label": _vm.$t('form.field.description') + ' (' + _vm.$t("locale.".concat(translation.locale)) + ')',
        "aria-required": "",
        "outlined": ""
      },
      on: {
        "input": function input($event) {
          return _vm.$emit('update:translations', _vm.newTranslations);
        }
      },
      scopedSlots: _vm._u([{
        key: "prepend-inner",
        fn: function fn() {
          return [_c('CommonFlagIcon', {
            attrs: {
              "locale": translation.locale
            }
          })];
        },
        proxy: true
      }], null, true),
      model: {
        value: translation.value,
        callback: function callback($$v) {
          _vm.$set(translation, "value", $$v);
        },
        expression: "translation.value"
      }
    })], 1)], 1);
  }), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', [_c('CommonAutocomplete', {
    attrs: {
      "items": _vm.sortedRights,
      "label": _vm.$tc('form.field.right', 2),
      "multiple": "",
      "item-text": "authority",
      "deletable-chips": "",
      "small-chips": "",
      "chips": "",
      "outlined": ""
    },
    on: {
      "input": _vm.updateRole
    },
    model: {
      value: _vm.rights,
      callback: function callback($$v) {
        _vm.rights = $$v;
      },
      expression: "rights"
    }
  })], 1)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }